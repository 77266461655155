import { lazy } from 'react';
import ProtectedRoute from 'components/common/ProtectedRoute';
import DefaultView from 'components/common/DefaultView';
import { Navigate } from 'react-router-dom';
import ReportPDF from 'components/views/BoundaryStats/pdf-report/RerportPDF';

const Main = lazy(() => import(/* webpackPrefetch: true */ 'components/views/main/Main'));
const NotFound = lazy(() => import('components/views/NotFound'));
const Login = lazy(() => import('components/views/Login'));
const AnalyzeIndex = lazy(() => import('components/views/AnalyzeIndex.js'));
const Routing = lazy(() => import('components/views/Routing.js'));
// [hygen] Import views

export const ROUTE_PATHS = {
  LOGIN: '/login',
  DEFAULT: '/',
  NOT_FOUND: '/404',
  ANALYZE_INDEX: '/analyze-index',
  REPORT: '/report',
  ROUTING: '/routing',
  // [hygen] Add path routes
};

const routes = [
  {
    path: ROUTE_PATHS.DEFAULT,
    element: (
      <ProtectedRoute>
        <DefaultView>
          <Main />
        </DefaultView>
      </ProtectedRoute>
    ),
    children: [
      {
        path: '/',
        element: <Navigate to='/analyze-index' />,
      },
      {
        path: ROUTE_PATHS.REPORT,
        element: <ReportPDF />,
      },
      { path: ROUTE_PATHS.ANALYZE_INDEX, element: <AnalyzeIndex /> },
      { path: ROUTE_PATHS.ROUTING, element: <Routing /> },
      // [hygen] Add routes
    ],
  },
  { path: ROUTE_PATHS.LOGIN, element: <Login /> },
  {
    path: '*',
    element: (
      <DefaultView>
        <NotFound />
      </DefaultView>
    ),
  },
];

export default routes;
