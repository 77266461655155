import { createSlice } from '@reduxjs/toolkit';

import {
  defaultH3DatasetWeightsByTopic,
  defaultH3TopicWeights,
} from 'data/config/h3RawDataConfig';
import { CRITICALITY_STARTING_WEIGHTS } from 'data/config/criticalityConfig';

const slice = createSlice({
  name: 'app',
  initialState: {
    error: null,
    bottomSheetOpen: false,
    // is there a spinner showing?
    loading: null, // null or a string value to be shown in the map overlay
    // select a boundary
    boundarySelected: false, // has the user selected a boundary to filter the index layers?
    selectedBoundarySource: null, // name of the source the user selected their boundary from
    selectedBoundaryConfig: null, // config of the user selected their boundary from
    SelectedBoundaryFeature: null, // feature of the user selected their boundary from
    drawNewShapeMode: null, // used with nebula to allow drawing modes on the map
    intersectingH3: null, // becomes a list of H3 ids that intersect the selected shape
    intersectingH3R6: null, // becomes a list of H3 ids that intersect the selected shape
    selectedUuid: null,
    boundaryProps: null,
    beforeReportView: null,
    // drawing a new boundary
    userDrawnWKT: null,
    bufferDist: 2.5,
    bufferUnits: 'miles',
    // select an index
    selectedIndexView: 'Boundary', // options are 'H3', 'Criticality', and 'Boundary'
    // h3 index customization
    h3SliderType: 'topic', // options are 'dataset' and 'topic'
    topicBeingAdjusted: 'Accessibility',
    topicWeights: defaultH3TopicWeights,
    datasetWeights: defaultH3DatasetWeightsByTopic,
    visibleDatasetName: null,
    // Criticality Index
    criticalityWeights: CRITICALITY_STARTING_WEIGHTS,
    // Weight Presets
    activeH3WeightPreset: 'Equal Weights',
    allH3Presets: [],
    activeCriticalityWeightPreset: 'Equal Weights',
    allCriticalityPresets: [],
  },
  reducers: {
    setError: (state, action) => {
      state.error = action.payload;
    },
    setBottomSheetOpen: (state, action) => {
      state.bottomSheetOpen = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setBoundarySelected: (state, action) => {
      state.boundarySelected = action.payload;
    },
    setBeforeReportView: (state, action) => {
      state.beforeReportView = action.payload;
    },
    setSelectedBoundarySource: (state, action) => {
      state.selectedBoundarySource = action.payload;
    },
    setSelectedBoundaryConfig: (state, action) => {
      state.selectedBoundaryConfig = action.payload;
    },
    setSelectedBoundaryFeature: (state, action) => {
      state.selectedBoundaryFeature = action.payload;
    },
    setDrawNewShapeMode: (state, action) => {
      state.drawNewShapeMode = action.payload;
    },
    setIntersectingH3: (state, action) => {
      state.intersectingH3 = action.payload;
    },
    setIntersectingH3R6: (state, action) => {
      state.intersectingH3R6 = action.payload;
    },
    setSelectedUuid: (state, action) => {
      state.selectedUuid = action.payload;
    },
    setUserDrawnWKT: (state, action) => {
      state.userDrawnWKT = action.payload;
    },
    setBufferDist: (state, action) => {
      state.bufferDist = action.payload;
    },
    setBufferUnits: (state, action) => {
      state.bufferUnits = action.payload;
    },
    setSelectedIndexView: (state, action) => {
      state.selectedIndexView = action.payload;
    },
    setH3SliderType: (state, action) => {
      state.h3SliderType = action.payload;
    },
    setTopicBeingAdjusted: (state, action) => {
      state.topicBeingAdjusted = action.payload;
    },
    setTopicWeights: (state, action) => {
      state.topicWeights = action.payload;
    },
    setDatasetWeights: (state, action) => {
      state.datasetWeights = action.payload;
    },
    setVisibleDatasetName: (state, action) => {
      state.visibleDatasetName = action.payload;
    },
    setCriticalityWeights: (state, action) => {
      state.criticalityWeights = action.payload;
    },
    setBoundaryProps: (state, action) => {
      state.boundaryProps = action.payload;
    },
    setActiveH3WeightPreset: (state, action) => {
      state.activeH3WeightPreset = action.payload;
    },
    setAllH3Presets: (state, action) => {
      state.allH3Presets = action.payload;
    },
    setActiveCriticalityWeightPreset: (state, action) => {
      console.log(action.payload);
      state.activeCriticalityWeightPreset = action.payload;
    },
    setAllCriticalityPresets: (state, action) => {
      state.allCriticalityPresets = action.payload;
    },
  },
});

export default slice.reducer;

export const setError = (payload) => ({ type: 'app/setError', payload });

export const setBeforeReportView = (payload) => ({
  type: 'app/setBeforeReportView',
  payload,
});

export const setBottomSheetOpen = (payload) => ({
  type: 'app/setBottomSheetOpen',
  payload,
});

export const setLoading = (payload) => ({
  type: 'app/setLoading',
  payload,
});

export const setBoundarySelected = (payload) => ({
  type: 'app/setBoundarySelected',
  payload,
});

export const setSelectedBoundaryConfig = (payload) => ({
  type: 'app/setSelectedBoundaryConfig',
  payload,
});

export const setSelectedBoundarySource = (payload) => ({
  type: 'app/setSelectedBoundarySource',
  payload,
});

export const setSelectedBoundaryFeature = (payload) => ({
  type: 'app/setSelectedBoundaryFeature',
  payload,
});

export const setDrawNewShapeMode = (payload) => ({
  type: 'app/setDrawNewShapeMode',
  payload,
});

export const setIntersectingH3 = (payload) => ({
  type: 'app/setIntersectingH3',
  payload,
});

export const setIntersectingH3R6 = (payload) => ({
  type: 'app/setIntersectingH3R6',
  payload,
});

export const setSelectedUuid = (payload) => ({
  type: 'app/setSelectedUuid',
  payload,
});

export const setUserDrawnWKT = (payload) => ({
  type: 'app/setUserDrawnWKT',
  payload,
});

export const setBufferDist = (payload) => ({
  type: 'app/setBufferDist',
  payload,
});

export const setBufferUnits = (payload) => ({
  type: 'app/setBufferUnits',
  payload,
});

export const setSelectedIndexView = (payload) => ({
  type: 'app/setSelectedIndexView',
  payload,
});

export const setH3SliderType = (payload) => ({
  type: 'app/setH3SliderType',
  payload,
});

export const setTopicBeingAdjusted = (payload) => ({
  type: 'app/setTopicBeingAdjusted',
  payload,
});

export const setTopicWeights = (payload) => ({
  type: 'app/setTopicWeights',
  payload,
});

export const setDatasetWeights = (payload) => ({
  type: 'app/setDatasetWeights',
  payload,
});

export const setVisibleDatasetName = (payload) => ({
  type: 'app/setVisibleDatasetName',
  payload,
});

export const setCriticalityWeights = (payload) => ({
  type: 'app/setCriticalityWeights',
  payload,
});

export const setBoundaryProps = (payload) => ({
  type: 'app/setBoundaryProps',
  payload,
});

export const setActiveH3WeightPreset = (payload) => ({
  type: 'app/setActiveH3WeightPreset',
  payload,
});

export const setAllH3Presets = (payload) => ({
  type: 'app/setAllH3Presets',
  payload,
});

export const setActiveCriticalityWeightPreset = (payload) => ({
  type: 'app/setActiveCriticalityWeightPreset',
  payload,
});

export const setAllCriticalityPresets = (payload) => ({
  type: 'app/setAllCriticalityPresets',
  payload,
});
