import { Grid, makeStyles, Typography } from '@material-ui/core';
import theme from 'theme';

const useStyles = makeStyles(() => ({
  logoBox: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem',
    marginBottom: '2rem',
  },
  hdotLogo: {
    maxWidth: '5%',
    color: 'white',
  },
  loadingBox: {
    display: 'flex',
    justifyContent: 'center',
    // marginBottom: '2rem',
  },
  textBox: {
    textAlign: 'center',
  },
  appName: {
    color: theme.palette.primary.contrastText,
  },
}));

export default function PageHeader({ className, logo, typographyProps }) {
  const classes = useStyles();
  return (
    <Grid container direction='row' justifyContent='space-between' alignItems='center'>
      <Grid item>
        <Typography
          className={className}
          {...{
            component: 'h1',
            variant: 'subtitle1',
            noWrap: true,
            ...typographyProps,
          }}
        >
          {logo}
        </Typography>
      </Grid>
      <Grid item className={classes.appName} justifyContent='flex-end'>
        <strong>Hawaii DOT</strong> Internal App
      </Grid>
    </Grid>
  );
}
