import { MAP_TYPES } from '@deck.gl/carto';

import { BQ_PROJECT_NAME, BQ_CONNECTION_NAME } from 'config';
import { LEGEND_TYPES } from '@carto/react-ui';
import { colorBins } from '@deck.gl/carto';

const DATA_COLOR = [217, 95, 2];
const NO_DATA_COLOR = [117, 112, 179];
const BLUE_COLOR = [0, 0, 255];

const ACS_LABELS = ['5%', '10%', '25%', '50%'];
const ACS_DOMAIN = [0.05, 0.1, 0.25, 0.5];
const ACS_COLORS = ['#f3e79b', '#f8a07e', '#eb7f86', '#ce6693', '#a059a0', '#5c53a5'];

const default_props = {
  getFillColor: [0, 0, 255],
  pointRadiusMinPixels: 6,
  getLineColor: [0, 0, 0, 50],
  lineWidthMinPixels: 2,
  pickable: true,
  autoHighlight: true,
};

const default_layer_config = {
  visible: true,
  switchable: false,
  showOpacityControl: true,
  opacity: 0.8,
  legend: {},
};

function makeID(prefix, text) {
  return `${prefix}_${text.replace('.', '_')}`;
}

let H3_INDEX_DATASETS = [
  {
    table: 'indexing_access.dept_of_health_buildings',
    nicename: 'Department of Health Buildings',
    popupField: 'facility',
    h3Column: 'dept_of_health_buildings',
    cartoLayerProps: { ...default_props },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.CATEGORY,
        labels: ['Locations of Department of Health Buildings'],
        colors: [BLUE_COLOR],
      },
    },
  },
  {
    table: 'indexing_access.hospitals',
    nicename: 'Hospitals',
    popupField: 'name',
    h3Column: 'hospitals',
    cartoLayerProps: { ...default_props },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.CATEGORY,
        labels: ['Locations of Hospitals'],
        colors: [BLUE_COLOR],
      },
    },
  },
  {
    table: 'indexing_access.wastewater_treatment_plants',
    nicename: 'Wastewater Treatment Plants',
    popupField: 'wwtp_name',
    h3Column: 'wastewater_treatment_plants',
    cartoLayerProps: { ...default_props },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.CATEGORY,
        labels: ['Locations of Wastewater Treatment Plants'],
        colors: [BLUE_COLOR],
      },
    },
  },
  {
    table: 'indexing_access.preschools',
    nicename: 'Preschools',
    popupField: 'name',
    h3Column: 'preschools',
    cartoLayerProps: { ...default_props },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.CATEGORY,
        labels: ['Locations of Preschools'],
        colors: [BLUE_COLOR],
      },
    },
  },
  {
    table: 'indexing_access.public_schools',
    nicename: 'Public Schools',
    popupField: 'sch_name',
    h3Column: 'public_schools',
    cartoLayerProps: { ...default_props },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.CATEGORY,
        labels: ['Locations of Public Schools'],
        colors: [BLUE_COLOR],
      },
    },
  },
  {
    table: 'indexing_access.private_schools',
    nicename: 'Private Schools',
    popupField: 'school',
    h3Column: 'private_schools',
    cartoLayerProps: { ...default_props },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.CATEGORY,
        labels: ['Locations of Private Schools'],
        colors: [BLUE_COLOR],
      },
    },
  },
  {
    table: 'indexing_access.postsecondary_institutions',
    nicename: 'Post-Secondary Institutions',
    popupField: 'inst_name',
    h3Column: 'postsecondary_institutions',
    cartoLayerProps: { ...default_props },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.CATEGORY,
        labels: ['Locations of Post-Secondary Institutions'],
        colors: [BLUE_COLOR],
      },
    },
  },
  {
    table: 'indexing_access.police_stations',
    nicename: 'Police Stations',
    popupField: 'name',
    h3Column: 'police_stations',
    cartoLayerProps: { ...default_props },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.CATEGORY,
        labels: ['Locations of Police Stations'],
        colors: [BLUE_COLOR],
      },
    },
  },
  {
    table: 'indexing_access.fire_stations',
    nicename: 'Fire Stations',
    popupField: 'name',
    h3Column: 'fire_stations',
    cartoLayerProps: { ...default_props },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.CATEGORY,
        labels: ['Locations of Fire Stations'],
        colors: [BLUE_COLOR],
      },
    },
  },
  {
    table: 'indexing_access.osm_access_pois',
    nicename: 'OpenStreetMap Access POIs',
    popupField: 'poi_type',
    h3Column: 'osm_access_pois',
    cartoLayerProps: { ...default_props },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.CATEGORY,
        labels: ['Locations of OSM Access POIs'],
        colors: [BLUE_COLOR],
      },
    },
  },
  {
    table: 'indexing_ecology.important_agricultural_lands',
    nicename: 'Important Agricultural Lands',
    popupField: 'docket_no',
    h3Column: 'important_agricultural_lands',
    cartoLayerProps: { ...default_props },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.CATEGORY,
        colors: [BLUE_COLOR],
        labels: ['Locations of Important Agricultural Lands'],
      },
    },
  },
  {
    table: 'indexing_ecology.special_management_areas',
    nicename: 'Special Management Areas',
    popupField: 'there are no name fields here',
    h3Column: 'special_management_areas',
    cartoLayerProps: { ...default_props },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.CATEGORY,
        colors: [BLUE_COLOR],
        labels: ['Locations of Special Management Areas'],
      },
    },
  },
  {
    table: 'indexing_ecology.areas_of_critical_habitat',
    nicename: 'Areas of Critical Habitat',
    popupField: 'there are no name fields here',
    h3Column: 'critical_habitat_present',
    cartoLayerProps: { ...default_props },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.CATEGORY,
        colors: [BLUE_COLOR],
        labels: ['Locations of Critical Habitat Areas'],
      },
    },
  },
  {
    table: 'indexing_ecology.threatened_and_endangered_plants',
    nicename: 'Threatened/Endangered Plants',
    popupField: 'density',
    h3Column: 'threatened_plant_concentration',
    cartoLayerProps: {
      ...default_props,
      getFillColor: (d) => {
        if (['VH', 'H'].includes(d.properties.density)) {
          return DATA_COLOR;
        } else {
          return NO_DATA_COLOR;
        }
      },
    },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.CATEGORY,
        colors: [DATA_COLOR, NO_DATA_COLOR],
        labels: ['Very High or High Concentration', 'Other values'],
      },
    },
  },
  // TODO: debug the wetlands layer... h3 doesn't seem right
  {
    table: 'indexing_ecology.wetlands',
    nicename: 'Wetlands',
    popupField: 'none',
    h3Column: 'wetlands_present',
    tileset: true,
    cartoLayerProps: { ...default_props },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.CATEGORY,
        colors: [BLUE_COLOR],
        labels: ['Wetlands Locations'],
      },
    },
  },
  {
    table: 'indexing_ecology.conservation_district_subzones',
    nicename: 'Conservation District Subzones',
    popupField: 'condist',
    h3Column: 'conservation_district_subzone',
    cartoLayerProps: {
      ...default_props,
      getFillColor: (d) => {
        if (d.properties.condist === 'P') {
          return [136, 65, 157];
        } else if (d.properties.condist === 'L') {
          return [140, 150, 198];
        } else if (d.properties.condist === 'G' || d.properties.condist === 'R') {
          return [179, 205, 227];
        } else {
          return [237, 248, 251];
        }
      },
    },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.CATEGORY,
        colors: [
          [136, 65, 157],
          [140, 150, 198],
          [179, 205, 227],
          [237, 248, 251],
        ],
        labels: [
          'Protective Subzone (P)',
          'Limited Subzone (L)',
          'General or Resource Subzone (G & R)',
          'All others',
        ],
      },
    },
  },
  {
    table: 'indexing_ecology.carbon_assessment_habitat_status',
    nicename: 'Native/Alien Vegetation',
    popupField: 'descriptio',
    tileset: true,
    h3Column: 'native_alien_vegetation',
    cartoLayerProps: {
      ...default_props,
      getFillColor: (d) => {
        if (d.properties.descriptio === 'Native Dominated') {
          return [136, 65, 157];
        } else if (d.properties.descriptio === 'Native / Alien Mix') {
          return [140, 150, 198];
        } else {
          return [237, 248, 251];
        }
      },
    },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.CATEGORY,
        colors: [
          [136, 65, 157],
          [140, 150, 198],
          [179, 205, 227],
        ],
        labels: ['Native Dominated', 'Native/Alien Mix', 'All others'],
      },
    },
  },
  {
    table: 'indexing_economy.aglanduse_2020',
    nicename: 'Agricultural Land Use',
    popupField: 'name',
    h3Column: 'ag_landuse_present',
    tileset: true,
    cartoLayerProps: { ...default_props },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.CATEGORY,
        colors: [BLUE_COLOR],
        labels: ['Locations with Agricultural Land Use'],
      },
    },
  },
  {
    table: 'indexing_economy.hotels',
    nicename: 'Hotels',
    popupField: 'name',
    h3Column: 'hotel_units',
    cartoLayerProps: { ...default_props },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.CATEGORY,
        colors: [BLUE_COLOR],
        labels: ['Locations of Hotels'],
      },
    },
  },
  {
    table: 'indexing_economy.osm_economy_pois',
    nicename: 'OpenStreetMap Economoy POIs',
    popupField: 'name',
    h3Column: 'osm_economy_pois',
    cartoLayerProps: { ...default_props },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.CATEGORY,
        colors: [BLUE_COLOR],
        labels: ['Locations with OSM Economy POIs'],
      },
    },
  },
  {
    table: 'indexing_economy.enterprise_zones',
    nicename: 'Enterprise Zones',
    popupField: 'name',
    h3Column: 'enterprise_zone_present',
    cartoLayerProps: { ...default_props },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.CATEGORY,
        colors: [BLUE_COLOR],
        labels: ['Locations of Enterprise Zones'],
      },
    },
  },
  {
    table: 'indexing_equity.affordable_housing_inventory',
    nicename: 'Affordable Housing Inventory',
    popupField: 'units',
    h3Column: 'affordable_housing_units',
    cartoLayerProps: { ...default_props },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.CATEGORY,
        colors: [BLUE_COLOR],
        labels: ['Locations of Affordable Housing Units'],
      },
    },
  },
  {
    table: 'indexing_equity.acs2020_5yr_B19013_median_hh_income',
    nicename: 'Median Household Income',
    popupField: 'b19013001',
    h3Column: 'median_hh_income',
    cartoLayerProps: {
      ...default_props,
      getFillColor: colorBins({
        attr: 'b19013001',
        domain: [10000, 50000, 100000, 150000, 200000],
        colors: 'Sunset',
      }),
    },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.BINS,
        labels: [10000, 50000, 100000, 150000, 200000],
        colors: ACS_COLORS,
      },
    },
  },
  {
    table: 'indexing_equity.acs2020_5yr_B17017_poverty_status',
    nicename: 'Percent of Households in Poverty',
    popupField: 'pct_households_in_poverty',
    h3Column: 'poverty_status',
    cartoLayerProps: {
      ...default_props,
      getFillColor: colorBins({
        attr: 'pct_households_in_poverty',
        domain: ACS_DOMAIN,
        colors: 'Sunset',
      }),
    },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.BINS,
        labels: ACS_LABELS,
        colors: ACS_COLORS,
      },
    },
  },
  {
    table: 'indexing_equity.socioneeds_index',
    nicename: 'SocioNeeds Index',
    popupField: 'index_',
    h3Column: 'socioneeds_index',
    cartoLayerProps: {
      ...default_props,
      getFillColor: colorBins({
        attr: 'index_',
        domain: [10, 25, 50, 75, 80],
        colors: 'Sunset',
      }),
    },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.BINS,
        labels: [10, 25, 50, 75, 80],
        colors: ACS_COLORS,
      },
    },
  },
  {
    table: 'indexing_equity.opportunity_zones',
    nicename: 'Opportunity Zones',
    popupField: 'name',
    h3Column: 'opportunity_zone_present',
    cartoLayerProps: { ...default_props },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.CATEGORY,
        colors: [BLUE_COLOR],
        labels: ['Locations of Opportunity Zones'],
      },
    },
  },
  // new files
  {
    table: 'indexing_climate.landslide_susceptibility',
    nicename: 'Landslide Susceptibility',
    popupField: 'ls_sus',
    tileset: true,
    h3Column: 'landslide_susceptibility',
    cartoLayerProps: {
      ...default_props,
      getLineColor: [0, 0, 0, 0],
      getFillColor: (d) => {
        if (d.properties.ls_sus === 'Very High') {
          return [136, 65, 157];
        } else if (d.properties.ls_sus === 'High') {
          return [140, 150, 198];
        } else if (d.properties.ls_sus === 'Moderate') {
          return [179, 205, 227];
        } else {
          return [237, 248, 251];
        }
      },
    },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.CATEGORY,
        colors: [
          [136, 65, 157],
          [140, 150, 198],
          [179, 205, 227],
          [237, 248, 251],
        ],
        labels: ['Very High', 'High', 'Moderate', 'Other values'],
      },
    },
  },

  {
    table: 'indexing_climate.sea_level_rise_exposure_area_combined',
    nicename: 'Exposure to Sea Level Rise',
    popupField: 'depth',
    h3Column: 'minimum_sea_level_exposure',
    cartoLayerProps: {
      ...default_props,
      getLineColor: [0, 0, 0, 0],
      getFillColor: (d) => {
        if (d.properties.depth === 0) {
          return [136, 65, 157];
        } else if (d.properties.depth === 1) {
          return [140, 150, 198];
        } else if (d.properties.depth === 2) {
          return [179, 205, 227];
        } else {
          return [237, 248, 251];
        }
      },
    },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.CATEGORY,
        colors: [
          [136, 65, 157],
          [140, 150, 198],
          [179, 205, 227],
          [237, 248, 251],
        ],
        labels: ['0.5ft Scenario', '1.1ft Scenario', '2.0ft Scenario', '3.2ft Scenario'],
      },
    },
  },
  {
    table: 'indexing_climate.storm_surge_cat_combined',
    nicename: 'Storm Surge',
    popupField: 'cat',
    h3Column: 'minimum_storm_surge_innundation_category',
    cartoLayerProps: {
      ...default_props,
      getLineColor: [0, 0, 0, 0],
      getFillColor: (d) => {
        if (d.properties.cat === 1) {
          return [136, 65, 157];
        } else if (d.properties.cat === 2) {
          return [140, 150, 198];
        } else if (d.properties.cat === 3) {
          return [179, 205, 227];
        } else {
          return [237, 248, 251];
        }
      },
    },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.CATEGORY,
        colors: [
          [136, 65, 157],
          [140, 150, 198],
          [179, 205, 227],
          [237, 248, 251],
        ],
        labels: ['CAT1 Storm', 'CAT2 Storm', 'CAT3 Storm', 'CAT4 Storm'],
      },
    },
  },
  {
    table: 'indexing_climate.wildfire_ignition',
    nicename: 'Wildfire Ignition Density',
    popupField: 'val_float',
    h3Column: 'wildfire_ignition_density',
    tileset: true,
    cartoLayerProps: {
      ...default_props,
      getLineColor: [0, 0, 0, 0],
      getFillColor: colorBins({
        attr: 'val_float',
        domain: [5, 10, 20, 30, 40],
        colors: 'Sunset',
      }),
    },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.BINS,
        labels: [5, 10, 20, 30, 40],
        colors: ACS_COLORS,
      },
    },
  },
  {
    table: 'indexing_climate.wildfire_risk_zones_2017',
    nicename: 'Wildfire Risk Rating',
    popupField: 'risk_ratin',
    h3Column: 'wildfire_risk_rating',
    cartoLayerProps: {
      ...default_props,
      getLineColor: [0, 0, 0, 0],
      getFillColor: (d) => {
        if (d.properties.risk_ratin === 'High') {
          return [136, 65, 157];
        } else if (d.properties.risk_ratin === 'Medium') {
          return [140, 150, 198];
        } else if (d.properties.risk_ratin === 'Low') {
          return [179, 205, 227];
        } else {
          return [237, 248, 251];
        }
      },
    },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.CATEGORY,
        colors: [
          [136, 65, 157],
          [140, 150, 198],
          [179, 205, 227],
          [237, 248, 251],
        ],
        labels: ['High', 'Medium', 'Low', 'Other values'],
      },
    },
  },
  {
    table: 'indexing_climate.lava_flow_hazard_zones',
    nicename: 'Lava Flow Hazard Zones',
    popupField: 'hzone_float',
    h3Column: 'lava_flow_hazard_zone',
    tileset: true,
    cartoLayerProps: {
      ...default_props,
      getLineColor: [0, 0, 0, 0],
      getFillColor: colorBins({
        attr: 'hzone_float',
        domain: [1, 3, 5, 7, 10],
        colors: 'Sunset',
      }),
    },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.BINS,
        labels: [1, 3, 5, 7, 10],
        colors: ACS_COLORS,
      },
    },
  },
  {
    table: 'indexing_equity.hawaiianhomelands15',
    nicename: 'Hawaiian Homelands',
    popupField: 'namelsad',
    h3Column: 'hawaiian_homelands',
    cartoLayerProps: { ...default_props },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.CATEGORY,
        colors: [BLUE_COLOR],
        labels: ['Locations of Hawaiian Homelands'],
      },
    },
  },
  {
    table: 'indexing_climate.tsunami_evac_zones',
    nicename: 'Tsunami Evacuation Zones',
    popupField: 'none',
    h3Column: 'tsunami_evac_zone',
    tileset: true,
    cartoLayerProps: { ...default_props },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.CATEGORY,
        colors: [BLUE_COLOR],
        labels: ['Locations of Tsunami Evacuation Zones'],
      },
    },
  },
  {
    table: 'indexing_climate.extreme_tsunami_evac_zones_v2',
    nicename: 'Extreme Tsunami Evacuation Zones',
    popupField: 'island',
    h3Column: 'extreme_tsunami_evac_zone',
    cartoLayerProps: { ...default_props },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.CATEGORY,
        colors: [BLUE_COLOR],
        labels: ['Locations of Extreme Tsunami Evacuation Zones'],
      },
    },
  },
  {
    table: 'indexing_climate.fema_flood_risk_censustracts_with_geoms',
    nicename: 'FEMA Flood Risk',
    popupField: 'rfld_risks_float',
    h3Column: 'fema_flood_risk',
    cartoLayerProps: {
      ...default_props,
      getLineColor: [0, 0, 0, 0],
      getFillColor: colorBins({
        attr: 'rfld_risks_float',
        domain: [5, 15, 25, 35, 50],
        colors: 'Sunset',
      }),
    },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.BINS,
        labels: [5, 15, 25, 35, 50],
        colors: ACS_COLORS,
      },
    },
  },
  {
    table: 'indexing_access.potential_emergency_shelters_combined',
    nicename: 'Potential Emergency Shelters',
    popupField: 'name',
    h3Column: 'potential_emergency_shelters',
    cartoLayerProps: { ...default_props },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.CATEGORY,
        colors: [BLUE_COLOR],
        labels: ['Locations of Potential Emergency Shelters'],
      },
    },
  },
  {
    table: 'indexing_equity.acs2020_5yr_B01001_sex_by_age_pct_under_5',
    nicename: '% of population under 5 years old',
    popupField: 'acs_pct_under_5',
    h3Column: 'acs_pct_under_5',
    cartoLayerProps: {
      ...default_props,
      getFillColor: colorBins({
        attr: 'acs_pct_under_5',
        domain: ACS_DOMAIN,
        colors: 'Sunset',
      }),
    },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.BINS,
        labels: ACS_LABELS,
        colors: ACS_COLORS,
      },
    },
  },
  {
    table: 'indexing_equity.acs2020_5yr_B01001_sex_by_age_pct_65_plus',
    nicename: '% of population 65 years and older',
    popupField: 'acs_pct_65_plus',
    h3Column: 'acs_pct_65_plus',
    cartoLayerProps: {
      ...default_props,
      getFillColor: colorBins({
        attr: 'acs_pct_65_plus',
        domain: ACS_DOMAIN,
        colors: 'Sunset',
      }),
    },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.BINS,
        labels: ACS_LABELS,
        colors: ACS_COLORS,
      },
    },
  },
  {
    table: 'indexing_equity.acs2020_5yr_c16002_limited_englishspeaking_households',
    nicename: '% of population with limited English proficiency',
    popupField: 'pct_limited_english',
    h3Column: 'acs_pct_limited_english',
    cartoLayerProps: {
      ...default_props,
      getFillColor: colorBins({
        attr: 'pct_limited_english',
        domain: ACS_DOMAIN,
        colors: 'Sunset',
      }),
    },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.BINS,
        labels: ACS_LABELS,
        colors: ACS_COLORS,
      },
    },
  },
  {
    table: 'indexing_equity.acs2020_5yr_B25003_percentage_of_renters',
    nicename: '% of households that rent',
    popupField: 'pct_renters',
    h3Column: 'acs_pct_renters',
    cartoLayerProps: {
      ...default_props,
      getFillColor: colorBins({
        attr: 'pct_renters',
        domain: ACS_DOMAIN,
        colors: 'Sunset',
      }),
    },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.BINS,
        labels: ACS_LABELS,
        colors: ACS_COLORS,
      },
    },
  },
  {
    table: 'indexing_equity.acs2020_5yr_B28003_households_with_broadband_internet',
    nicename: '% of households without broadband internet',
    popupField: 'pct_without_broadband',
    h3Column: 'acs_pct_without_broadband',
    cartoLayerProps: {
      ...default_props,
      getFillColor: colorBins({
        attr: 'pct_without_broadband',
        domain: ACS_DOMAIN,
        colors: 'Sunset',
      }),
    },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.BINS,
        labels: ACS_LABELS,
        colors: ACS_COLORS,
      },
    },
  },
  {
    table: 'indexing_equity.hazardous_waste_proximity',
    nicename: 'Hazardous Waste Proximity',
    popupField: 'p_ptsdf',
    h3Column: 'hazardous_waste_proximity_percentile',
    cartoLayerProps: {
      ...default_props,
      getLineColor: [0, 0, 0, 0],
      getFillColor: colorBins({
        attr: 'p_ptsdf',
        domain: [5, 20, 40, 60, 80],
        colors: 'Sunset',
      }),
    },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.BINS,
        labels: [5, 20, 40, 60, 80],
        colors: ACS_COLORS,
      },
    },
  },
  {
    table:
      'indexing_equity.transit_connectivity_index_hawaii_blockgroups_with_geoms_employment',
    nicename: 'Employment Access Index',
    popupField: 'employment_access_index',
    h3Column: 'employment_access_index',
    cartoLayerProps: {
      ...default_props,
      getLineColor: [0, 0, 0, 0],
      getFillColor: colorBins({
        attr: 'employment_access_index',
        domain: [100, 1000, 50000, 100000, 125000],
        colors: 'Sunset',
      }),
    },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.BINS,
        labels: [100, 1000, 50000, 100000, 125000],
        colors: ACS_COLORS,
      },
    },
  },
  {
    table:
      'indexing_equity.transit_connectivity_index_hawaii_blockgroups_with_geoms_transportation_cost',
    nicename: 'Annual Transportation Cost',
    popupField: 'annual_transportation_cost',
    h3Column: 'annual_transportation_cost',
    cartoLayerProps: {
      ...default_props,
      getLineColor: [0, 0, 0, 0],
      getFillColor: colorBins({
        attr: 'annual_transportation_cost',
        domain: [10, 15, 20, 25, 30],
        colors: 'Sunset',
      }),
    },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.BINS,
        labels: [10, 15, 20, 25, 30],
        colors: ACS_COLORS,
      },
    },
  },
  {
    table:
      'indexing_equity.acs2020_5yr_B27010_persons_without_health_insurance_under_65yrs_with_geoms',
    nicename: '% of population without medical insurance',
    popupField: 'pct_uninsured',
    h3Column: 'acs_pct_uninsured',
    cartoLayerProps: {
      ...default_props,
      getFillColor: colorBins({
        attr: 'pct_uninsured',
        domain: ACS_DOMAIN,
        colors: 'Sunset',
      }),
    },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.BINS,
        labels: ACS_LABELS,
        colors: ACS_COLORS,
      },
    },
  },
  {
    table: 'indexing_equity.medically_underserved_area_designation_boundaries',
    nicename: 'Medically Underserved Areas',
    popupField: 'TODO',
    h3Column: 'medically_underserved_area',
    cartoLayerProps: { ...default_props },
    layerConfig: {
      ...default_layer_config,
      legend: {
        type: LEGEND_TYPES.CATEGORY,
        colors: [BLUE_COLOR],
        labels: ['Locations of Medically Underserved Areas'],
      },
    },
  },
];

H3_INDEX_DATASETS.forEach((config) => {
  config['sourceId'] = makeID('source', config.table);
  config['layerId'] = makeID('layer', config.table);
  config.layerConfig['title'] = config.nicename;
});

H3_INDEX_DATASETS.forEach((config) => {
  if (config.tileset) {
    config['source'] = {
      id: config.sourceId,
      type: MAP_TYPES.TILESET,
      connection: BQ_CONNECTION_NAME,
      data: `${BQ_PROJECT_NAME}.${config.table}_tileset`,
      layerId: config.layerId,
      cartoLayerProps: config.cartoLayerProps,
    };
  } else {
    config['source'] = {
      id: config.sourceId,
      type: MAP_TYPES.TABLE,
      connection: BQ_CONNECTION_NAME,
      data: `${BQ_PROJECT_NAME}.${config.table}`,
      layerId: config.layerId,
      cartoLayerProps: config.cartoLayerProps,
    };
  }
});

const H3_DATA_LOOKUP = {};
H3_INDEX_DATASETS.map((config) => {
  return (H3_DATA_LOOKUP[config.h3Column] = config);
});

const topicLookup = {
  access: 'Accessibility',
  equity: 'Equity',
  economy: 'Economy',
  ecology: 'Ecology',
  climate: 'Climate',
};

let defaultH3DatasetWeightsByTopic = {};
let defaultH3TopicWeights = {};
H3_INDEX_DATASETS.forEach((config) => {
  const bq_dataset = config.table.split('.')[0].replace('indexing_', '');
  const topic = topicLookup[bq_dataset];
  if (!Object.keys(defaultH3DatasetWeightsByTopic).includes(topic)) {
    defaultH3DatasetWeightsByTopic[topic] = {};
  }

  defaultH3DatasetWeightsByTopic[topic][config.h3Column] = 0.5;

  if (!Object.keys(defaultH3TopicWeights).includes(topic)) {
    defaultH3TopicWeights[topic] = 0.5;
  }
});

export {
  H3_INDEX_DATASETS,
  H3_DATA_LOOKUP,
  defaultH3DatasetWeightsByTopic,
  defaultH3TopicWeights,
};
