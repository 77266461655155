const criticalityTopics = [
  {
    colName: 'aadt',
    label: 'AADT',
    weight: 0.5,
  },
  {
    colName: 'functional_class',
    label: 'Functional Classification',
    weight: 0.5,
  },
  {
    colName: 'truck',
    label: 'Truck Route',
    weight: 0.5,
  },
  {
    colName: 'fire_stations',
    label: 'Fire Station Proximity',
    weight: 0.5,
  },
  {
    colName: 'hospitals',
    label: 'Hospital Proximity',
    weight: 0.5,
  },
  {
    colName: 'police_stations',
    label: 'Police Station Proximity',
    weight: 0.5,
  },
  {
    colName: 'preschools',
    label: 'Preschool Proximity',
    weight: 0.5,
  },
  {
    colName: 'public_schools',
    label: 'Public School Proximity',
    weight: 0.5,
  },
  {
    colName: 'private_schools',
    label: 'Private School Proximity',
    weight: 0.5,
  },
];

const CRITICALITY_STARTING_WEIGHTS = {};
const CRITICALITY_LABELS = {};
criticalityTopics.forEach((config) => {
  CRITICALITY_STARTING_WEIGHTS[config.colName] = config.weight;
  CRITICALITY_LABELS[config.colName] = config.label;
});

export { CRITICALITY_STARTING_WEIGHTS, CRITICALITY_LABELS };
