export const BOUNDARY_PROPS = [
  {
    topic: 'Equity Summary',
    data: [
      {
        title: 'acs_pct_pop_under_5',
        nicename: 'of the population is under the age of 5',
        format: 'pct',
      },
      {
        title: 'acs_pct_pop_65_plus',
        nicename: 'of the population is age 65 and up',
        format: 'pct',
      },
      {
        title: 'acs_pct_households_limited_english',
        nicename: 'of households have limited English proficiency',
        format: 'pct',
      },
      {
        title: 'acs_pct_households_in_poverty',
        nicename: 'of households are in poverty',
        format: 'pct',
      },
      {
        title: 'acs_median_hh_income',
        nicename: 'Median Household Income',
        format: 'currency',
      },
      {
        title: 'acs_pct_housing_units_renter_occupied',
        nicename: 'of housing units are renter-occupied',
        format: 'pct',
      },
      {
        title: 'acs_pct_households_with_broadband',
        nicename: 'of households have broadband internet',
        format: 'pct',
      },
      {
        title: 'acs_pct_unemployed',
        nicename: 'of people in the labor market are unemployed',
        format: 'pct',
      },
    ],
  },
  {
    topic: 'Critical Facilities Within Selected Area',
    data: [
      {
        title: 'hotels',
        nicename: 'Hotels',
        format: 'int',
      },
      {
        title: 'osm_access_pois',
        nicename: 'OpenStreetMap "Access" POIs',
        format: 'int',
      },
      {
        title: 'osm_economy_pois',
        nicename: 'OpenStreetMap "Economy" POIs',
        format: 'int',
      },
      {
        title: 'dept_of_health_buildings',
        nicename: 'DOH Buildings',
        format: 'int',
      },
      {
        title: 'hospitals',
        nicename: 'Hospitals',
        format: 'int',
      },
      {
        title: 'wastewater_treatment_plants',
        nicename: 'Wastewater Treatment Plants',
        format: 'int',
      },
      {
        title: 'preschools',
        nicename: 'Preschools',
        format: 'int',
      },
      {
        title: 'private_schools',
        nicename: 'Private Schools',
        format: 'int',
      },
      {
        title: 'postsecondary_institutions',
        nicename: 'Postsecondary Institutions',
        format: 'int',
      },
      {
        title: 'police_stations',
        nicename: 'Police Stations',
        format: 'int',
      },
      {
        title: 'fire_stations',
        nicename: 'Fire Stations',
        format: 'int',
      },
    ],
  },
];
