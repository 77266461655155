import { Grid, makeStyles } from '@material-ui/core';
import theme from 'theme';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import ReportHeader from './components/ReportHeader';
import ReportContent from './components/ReportContent';
import { useReactToPrint } from 'react-to-print';
import { useMemo, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setViewState } from '@carto/react-redux';

import { ROUTE_PATHS } from 'routes';
import { useAppContext } from 'context/AppContext';
import { setBeforeReportView } from 'store/appSlice';

const useStyles = makeStyles({
  root: {
    backgroundColor: theme.palette.background.default,
    width: '100%',
    height: '100%',
    position: 'fixed',
    zIndex: '1!important',
  },
  wrapper: {
    maxHeight: `calc(100vh - ${theme.spacing(6)}px)`,
    overflow: 'auto',
  },
  reportWrapper: {
    marginTop: theme.spacing(2.5),
    '@media print': {
      marginTop: 0,
    },
  },
});

export default function ReportPDF() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    state: { type, boundaryName: stateBoundaryName },
  } = useLocation();
  const boundarySelected = useSelector((state) => state.app.boundarySelected);
  const boundaryProps = useSelector((state) => state.app.boundaryProps);
  const selectedBoundaryConfig = useSelector((state) => state.app.selectedBoundaryConfig);
  const beforeReportView = useSelector((state) => state.app.beforeReportView);

  const componentRef = useRef(null);
  let boundaryName = useMemo(
    () =>
      boundarySelected && boundaryProps && selectedBoundaryConfig
        ? boundaryProps[selectedBoundaryConfig.popupField]
        : '',
    [boundarySelected, boundaryProps, selectedBoundaryConfig]
  );
  if (type === 'routing') {
    boundaryName = stateBoundaryName;
  }
  // center map
  const { deckInstance } = useAppContext();

  const handlePrint = useReactToPrint({
    pageStyle: `@page { size: A4; margin: 24px; }`,
    documentTitle: 'Report',
    content: () => componentRef.current,
  });

  if (deckInstance.current === null) {
    return <Navigate to={ROUTE_PATHS.ANALYZE_INDEX} />;
  }

  const handleClose = () => {
    dispatch(setViewState(beforeReportView));
    dispatch(setBeforeReportView(null));
    navigate(-1);
  };

  return (
    <div className={classes.root}>
      <ReportHeader onDownload={handlePrint} onClose={handleClose} title={boundaryName} />
      <Grid container className={classes.wrapper}>
        <Grid
          ref={componentRef}
          container
          justifyContent='center'
          className={classes.reportWrapper}
        >
          <ReportContent name={boundaryName} />
        </Grid>
      </Grid>
    </div>
  );
}
