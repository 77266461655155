import { BASEMAPS } from '@carto/react-basemaps';
import { Box, makeStyles } from '@material-ui/core';
import { useAppContext } from 'context/AppContext';
import html2canvas from 'html2canvas';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ShowSpinner } from '../../../../common/HDOT/HDOT';
import { BASEMAP_TYPES } from '../../../../common/map/Map';

const useStyles = makeStyles(() => ({
  root: {
    // flex: '1 1 auto',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '-webkit-print-color-adjust': 'exact',
    'print-color-adjust': 'exact',
    height: '350px',
    width: '745px',
    '@media print': {
      marginTop: 0,
    },
  },
}));

export function MapScreenShoot() {
  const classes = useStyles();
  const [screenshots, setScreenshots] = useState({ deckCanvas: '', basemapCanvas: '' });
  const { deckInstance, baseMapInstance, googleMapInstance } = useAppContext();
  const isGmaps = useSelector(
    (state) => BASEMAPS[state.carto.basemap]?.type === BASEMAP_TYPES.gmaps
  );

  useEffect(
    () =>
      deckInstance &&
      (googleMapInstance || baseMapInstance) &&
      takeMapScreenshot(
        deckInstance.current,
        baseMapInstance.current,
        googleMapInstance.current,
        isGmaps
      ).then((res) => setScreenshots(res)),
    [deckInstance, baseMapInstance, googleMapInstance, isGmaps]
  );

  return (
    <Box
      className={classes.root}
      style={{
        backgroundImage: `url(${screenshots.deckCanvas}), url(${screenshots.basemapCanvas})`,
      }}
    >
      {(screenshots?.deckCanvas === '' || screenshots?.basemapCanvas === '') && (
        <ShowSpinner text={'Loading image...'} />
      )}
    </Box>
  );
}

async function takeMapScreenshot(
  deckInstance,
  baseMapInstance,
  googleMapInstance,
  isGmaps
) {
  if (isGmaps) {
    const googleCanvas = await html2canvas(googleMapInstance.getDiv(), {
      useCORS: true,
    });
    const basemapCanvas = googleCanvas.toDataURL();

    const canvas = window.cartoDeck._deck.canvas;
    canvas.getContext('webgl', { preserveDrawingBuffer: true });
    window.cartoDeck._deck.redraw(true);
    const deckCanvas = canvas.toDataURL();
    canvas.getContext('webgl', { preserveDrawingBuffer: false });
    return { deckCanvas, basemapCanvas };
  }

  const canvas = baseMapInstance.getMap().getCanvas();
  canvas.getContext('webgl', { preserveDrawingBuffer: true });
  baseMapInstance.getMap()._render();
  const basemapCanvas = canvas.toDataURL();
  canvas.getContext('webgl', { preserveDrawingBuffer: false });
  const canvasDeck = deckInstance.canvas;
  canvasDeck.getContext('webgl', { preserveDrawingBuffer: true });
  deckInstance.redraw(true);
  const deckCanvas = canvasDeck.toDataURL();
  canvasDeck.getContext('webgl', { preserveDrawingBuffer: false });

  return { deckCanvas, basemapCanvas };
}
