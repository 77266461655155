import {
  Box,
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
} from '@material-ui/core';
import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';

const formatCoordinates = (coordinates) =>
  !!coordinates?.length
    ? `Longitude: ${coordinates[0].toFixed(5)}\nLatitude: ${coordinates[1].toFixed(5)}`
    : '';

const useStyles = makeStyles(() => ({
  smallFontSize: {
    fontSize: 14,
  },
}));

export default function InputField({
  onClick,
  onClear,
  label,
  value,
  focused,
  readonly,
  startIcon,
  formatter = formatCoordinates,
  deletable = true,
}) {
  const classes = useStyles();
  return (
    <Box mt={1}>
      <TextField
        label={label}
        variant={'outlined'}
        multiline={value !== ''}
        maxRows={2}
        onClick={onClick}
        value={formatter(value)}
        focused={focused}
        hover={focused}
        readOnly={readonly}
        InputProps={{
          classes: {
            input: classes.smallFontSize,
          },
          startAdornment: startIcon && value && (
            <InputAdornment
              position='start'
              style={{
                height: 'auto',
                marginTop: 8,
              }}
            >
              {startIcon}
            </InputAdornment>
          ),
          endAdornment: deletable && (
            <IconButton
              style={{ display: value !== '' ? 'block' : 'none' }}
              onClick={onClear}
            >
              <DeleteIcon />
            </IconButton>
          ),
        }}
        sx={{
          m: 2,
          '& .Mui-focused .MuiIconButton-root': { color: 'primary.main' },
        }}
      />
    </Box>
  );
}
