import {
  AppBar,
  Button,
  IconButton,
  makeStyles,
  Toolbar,
  Typography,
} from '@material-ui/core';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import CloseIcon from '@material-ui/icons/Close';

const useHeaderStyles = makeStyles((theme) => ({
  contentHeader: {
    zIndex: 1,
  },
  headerReport: {
    justifyContent: 'space-between',
    minHeight: theme.spacing(6),
    '@media print': {
      display: 'none',
    },
  },
  downloadButton: {
    color: theme.palette.primary.contrastText,
    border: `2px solid ${theme.palette.primary.contrastText}`,
    padding: `${theme.spacing(0.75)}px ${theme.spacing(2)}px`,
  },
  title: {
    fontWeight: 600,
    color: theme.palette.primary.contrastText,
  },
  iconButton: {
    color: theme.palette.primary.contrastText,
  },
}));

export default function ReportHeader({ onDownload, onClose, title }) {
  const classes = useHeaderStyles();

  return (
    <div className={classes.contentHeader}>
      <AppBar position='fixed'>
        <Toolbar className={classes.headerReport}>
          <IconButton className={classes.iconButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant='subtitle1' color='inherit'>
            {title} Report
          </Typography>
          <Button
            color='primary'
            size='small'
            className={`${classes.downloadButton} ${classes.iconButton}`}
            startIcon={<SaveAltIcon />}
            onClick={onDownload}
          >
            Download
          </Button>
        </Toolbar>
      </AppBar>
    </div>
  );
}
