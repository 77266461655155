import {
  addLayer,
  addSource,
  removeLayer,
  removeSource,
  updateLayer,
} from '@carto/react-redux';
import { sum } from 'd3-array';
import { BOUNDARY_DATASETS } from 'data/config/boundaryConfig';
import { H3_INDEX_DATASETS } from 'data/config/h3RawDataConfig';

const addNewSource = (source, dispatch) => {
  dispatch(addSource(source));
  dispatch(addLayer({ id: source.layerId, source: source.id }));
};

const addNewLayer = (source, dispatch) => {
  dispatch(addLayer({ id: source.layerId, source: source.id }));
};

const toggleLayerVisibility = (layerId, isVisible, dispatch) => {
  dispatch(
    updateLayer({
      id: layerId,
      layerAttributes: {
        visible: isVisible,
      },
    })
  );
};

const turnOffAllBoundaryLayers = (dispatch) => {
  BOUNDARY_DATASETS.forEach((config) => {
    toggleLayerVisibility(config.layerId, false, dispatch);
  });
};

const turnOffAllBoundaryLayersExcept = (thisLayer, dispatch) => {
  BOUNDARY_DATASETS.forEach((config) => {
    if (config.layerId !== thisLayer) {
      toggleLayerVisibility(config.layerId, false, dispatch);
    }
  });
};

const turnOffAllH3RawDataLayers = (dispatch) => {
  H3_INDEX_DATASETS.forEach((config) => {
    dispatch(removeLayer(config.layerId));
    dispatch(removeSource(config.sourceId));
  });
};

const turnOffAllH3RawDataLayersExcept = (thisLayer, dispatch) => {
  H3_INDEX_DATASETS.forEach((config) => {
    if (config.layerId !== thisLayer) {
      dispatch(removeLayer(config.layerId));
      dispatch(removeSource(config.sourceId));
    }
  });
};

const scaleWeights = (weights) => {
  // scale the weights so that they all add up to 1
  const scaledWeights = { ...weights };

  const sum_of_weights = sum(Object.values(scaledWeights));

  Object.keys(scaledWeights).forEach((k) => {
    scaledWeights[k] = scaledWeights[k] / sum_of_weights;
  });

  return scaledWeights;
};

const scaleAllWeights = (topicWeights, datasetWeights) => {
  const allWeights = {};

  // scale the topic weights
  const scaledTopics = scaleWeights(topicWeights);

  // for each topic, scale the dataset weights, and then scale by topic weight
  Object.keys(scaledTopics).forEach((topic) => {
    const dataWeights = scaleWeights(datasetWeights[topic]);
    const weightOfThisTopic = scaledTopics[topic];
    Object.keys(dataWeights).forEach((dataset) => {
      allWeights[dataset] = dataWeights[dataset] * weightOfThisTopic;
    });
  });

  return allWeights;
};

export {
  toggleLayerVisibility,
  addNewSource,
  turnOffAllBoundaryLayers,
  turnOffAllBoundaryLayersExcept,
  scaleAllWeights,
  scaleWeights,
  turnOffAllH3RawDataLayers,
  turnOffAllH3RawDataLayersExcept,
  addNewLayer,
};
