import {
  Box,
  Divider,
  Grid,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  listItem: {
    padding: '0',
    height: 'auto',
  },
}));

export default function Stat({ label, value, format }) {
  const classes = useStyles();

  let displayValue = '';

  if (!value) {
    if (format === 'pct') {
      displayValue = 'n/a';
    } else if (format === 'int') {
      displayValue = 0;
    }
  } else {
    if (format === 'pct') {
      let multipliedValue = value * 100;
      displayValue = multipliedValue.toFixed(1) + '%';
    } else if (format === 'currency') {
      const thousands = value / 1000;
      displayValue = '$' + thousands.toFixed(1) + 'K';
    } else {
      displayValue = value;
    }
  }

  return (
    <>
      <Divider component='li' />
      <ListItem className={classes.listItem}>
        <ListItemText
          primaryTypographyProps={{ noWrap: false }}
          primary={
            <Box py={0.5} px={0}>
              <Grid container alignItems='baseline' justifyContent='space-between'>
                <Grid item xs={3} justifyContent={'center'}>
                  <Typography variant='subtitle2' color='textPrimary'>
                    {displayValue}
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography
                    variant='caption'
                    style={{ fontWeight: 300, lineHeight: 1.2 }}
                    color='textPrimary'
                    textAlign={'left'}
                  >
                    {label}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          }
        />
      </ListItem>
    </>
  );
}
