import { executeSQL } from '@carto/react-api';
import { Box, List, makeStyles, Typography } from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BQ_CONNECTION_NAME } from '../../../../../config';
import {
  queryAverageCriticalityIndex,
  queryAverageH3Index,
} from '../../../../../data/sql_get_index_average';
import Stat from '../stat/Stat';

const useStyles = makeStyles(() => ({
  wrapper: { width: '100%' },
  topic: {
    fontWeight: 'bold',
  },
  caption: { fontWeight: 300, lineHeight: 1.2, color: 'rgba(44, 48, 50, 0.6)' },
}));

export function AVGIndex() {
  const classes = useStyles();

  const [avgCriticality, setAvgCriticality] = useState('Computing...');
  const [avgH3, setAvgH3] = useState('Computing...');

  const selectedBoundarySource = useSelector((state) => state.app.selectedBoundarySource);
  const selectedUuid = useSelector((state) => state.app.selectedUuid);
  const criticalityWeights = useSelector((state) => state.app.criticalityWeights);
  const credentials = useSelector((state) => state.carto.credentials);
  const topicWeights = useSelector((state) => state.app.topicWeights);
  const datasetWeights = useSelector((state) => state.app.datasetWeights);
  const intersectingH3 = useSelector((state) => state.app.intersectingH3);

  useEffect(() => {
    async function fetchData() {
      const query = queryAverageCriticalityIndex(
        criticalityWeights,
        selectedUuid,
        selectedBoundarySource,
        intersectingH3
      );

      const result = await executeSQL({
        credentials,
        query,
        connection: BQ_CONNECTION_NAME,
        queryParameters: null
      });

      if (result && result[0].avg_score) {
        const avgValue = result[0].avg_score.toFixed(3);
        setAvgCriticality(avgValue);
      }
    }

    fetchData();
    return () => {
      setAvgCriticality('');
    };
  }, [
    credentials,
    criticalityWeights,
    intersectingH3,
    selectedBoundarySource,
    selectedUuid,
  ]);

  useEffect(() => {
    async function fetchData() {
      const query = queryAverageH3Index(
        topicWeights,
        datasetWeights,
        selectedUuid,
        selectedBoundarySource,
        intersectingH3
      );

      const result = await executeSQL({
        credentials,
        query,
        connection: BQ_CONNECTION_NAME,
        queryParameters: null
      });

      if (result && result[0].avg_score) {
        const avgValue = result[0].avg_score.toFixed(3);
        setAvgH3(avgValue);
      }
    }

    fetchData();
    return () => {
      setAvgH3('');
    };
  }, [
    credentials,
    intersectingH3,
    topicWeights,
    datasetWeights,
    selectedBoundarySource,
    selectedUuid,
  ]);

  return (
    <Box mb={1} className={classes.wrapper}>
      <Box py={2}>
        <Typography variant='subtitle1' className={classes.topic}>
          Index Averages Within Selected Area
        </Typography>
      </Box>
      <List disablePadding>
        <Stat label='Average Criticality index' value={avgCriticality} format='float' />
        <Stat label='Average Vulnerability index' value={avgH3} format='float' />
      </List>

      <Box mt={3} mb={1}>
        <Typography
          variant='caption'
          className={classes.caption}
          color='disabled'
          component={'p'}
          textAlign={'left'}
        >
          0 = lowest possible score
        </Typography>
        <Typography
          variant='caption'
          className={classes.caption}
          color='disabled'
          component={'p'}
          textAlign={'left'}
        >
          1 = highest possible score
        </Typography>
      </Box>
    </Box>
  );
}
