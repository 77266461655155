import { executeSQL } from '@carto/react-api';
import { Typography } from '@material-ui/core';
import { BQ_CONNECTION_NAME, BQ_PROJECT_NAME } from 'config';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BoundaryProps from '../boundary-props/BoundaryProps';

export default function DynamicProps({
  StatGroupWrapper = React.Fragment,
  statGroupDivider = true,
}) {
  const [dynamicProps, setDynamicProps] = useState(null);
  const selectedUuid = useSelector((state) => state.app.selectedUuid);
  const credentials = useSelector((state) => state.carto.credentials);
  useEffect(() => {
    async function fetchData() {
      const query = `select * from \`${BQ_PROJECT_NAME}\`.internal_app_us.GetCustomBoundaryStats('${selectedUuid}')`;
      const result = await executeSQL({
        credentials,
        query,
        connection: BQ_CONNECTION_NAME,
        queryParameters: {}
      });
      setDynamicProps(result);
    }
    fetchData();
  }, [credentials, selectedUuid]);

  return (
    <>
      {!dynamicProps && (
        <Typography variant='subtitle1'>
          Computing values for this custom-drawn shape...
        </Typography>
      )}
      {!!dynamicProps?.length && (
        <BoundaryProps
          props={dynamicProps[0]}
          StatGroupWrapper={StatGroupWrapper}
          withDivider={statGroupDivider}
        />
      )}
    </>
  );
}
