import { useDispatch, useSelector } from 'react-redux';
import { WrapperWidgetUI } from '@carto/react-ui';
import {
  clearDestination,
  clearSource,
  ENDPOINT_DESTINATION,
  ENDPOINT_SOURCE,
  setEndpointToDestination,
  setEndpointToSource,
} from '../../../store/routingSlice';
import InputField from './source-destination-selector-widget/InputField';
import { ReactComponent as BlueMarkerIcon } from 'assets/img/marker-blue.svg';
import { ReactComponent as OrangeMarkerIcon } from 'assets/img/marker-orange.svg';
import { Box } from '@material-ui/core';

export const SourceDestinationInputs = ({ deletable = true }) => {
  const dispatch = useDispatch();
  const { routeSource, routeDestination, endpointSelected } = useSelector(
    (state) => state.routing
  );

  return (
    <>
      <InputField
        label='Source'
        onClick={() => dispatch(setEndpointToSource())}
        onClear={() => dispatch(clearSource())}
        value={routeSource}
        focused={endpointSelected === ENDPOINT_SOURCE}
        startIcon={<BlueMarkerIcon />}
        deletable={deletable}
      />

      <InputField
        label='Destination'
        onClick={() => dispatch(setEndpointToDestination())}
        onClear={() => dispatch(clearDestination())}
        value={routeDestination}
        focused={endpointSelected === ENDPOINT_DESTINATION}
        startIcon={<OrangeMarkerIcon />}
        deletable={deletable}
      />
    </>
  );
};
const SourceDestinationSelectortWidget = () => {
  return (
    <WrapperWidgetUI
      expandable={false}
      onExpandedChange={function noRefCheck() {}}
      title={'Select source and destination'}
    >
      <Box style={{ height: '260px' }}>
        <p>Click the Source box below and select a point on the map, then click the Destination box and again select a point on the map for the coordinates.</p>
        <SourceDestinationInputs />
      </Box>
    </WrapperWidgetUI>
  );
};

export default SourceDestinationSelectortWidget;
