import { createContext, useRef, useContext } from 'react';

const AppContext = createContext({
  deckInstance: null,
  baseMapInstance: null,
  setDeckInstance: () => {},
  setBaseMapInstance: () => {},
});

export default AppContext;

export const AppContextProvider = AppContext.Provider;

export const useAppContextValues = () => {
  const deckInstance = useRef(null);
  const baseMapInstance = useRef(null);
  const googleMapInstance = useRef(null);

  const setDeckInstance = (deckRef) => {
    if (deckRef?.deck) {
      deckInstance.current = deckRef.deck;
    }
  };

  const setBaseMapInstance = (baseMapRef) => {
    if (baseMapRef) {
      baseMapInstance.current = baseMapRef;
    }
  };
  const setGoogleBaseMapInstance = (baseMapRef) => {
    if (baseMapRef) {
      googleMapInstance.current = baseMapRef;
    }
  };

  const contextValues = {
    deckInstance,
    baseMapInstance,
    googleMapInstance,
    setDeckInstance,
    setBaseMapInstance,
    setGoogleBaseMapInstance,
  };

  return contextValues;
};

export const useAppContext = () => useContext(AppContext);
