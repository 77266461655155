(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@carto/react-core"), require("@deck.gl/carto"), require("@carto/react-core/"), require("react"), require("react-redux"), require("@carto/react-redux"), require("@carto/react-workers"), require("@deck.gl/core"), require("@deck.gl/extensions"));
	else if(typeof define === 'function' && define.amd)
		define(["@carto/react-core", "@deck.gl/carto", "@carto/react-core/", "react", "react-redux", "@carto/react-redux", "@carto/react-workers", "@deck.gl/core", "@deck.gl/extensions"], factory);
	else if(typeof exports === 'object')
		exports["cartoReactApi"] = factory(require("@carto/react-core"), require("@deck.gl/carto"), require("@carto/react-core/"), require("react"), require("react-redux"), require("@carto/react-redux"), require("@carto/react-workers"), require("@deck.gl/core"), require("@deck.gl/extensions"));
	else
		root["cartoReactApi"] = factory(root["@carto/react-core"], root["@deck.gl/carto"], root["@carto/react-core/"], root["react"], root["react-redux"], root["@carto/react-redux"], root["@carto/react-workers"], root["@deck.gl/core"], root["@deck.gl/extensions"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__485__, __WEBPACK_EXTERNAL_MODULE__332__, __WEBPACK_EXTERNAL_MODULE__38__, __WEBPACK_EXTERNAL_MODULE__156__, __WEBPACK_EXTERNAL_MODULE__756__, __WEBPACK_EXTERNAL_MODULE__347__, __WEBPACK_EXTERNAL_MODULE__116__, __WEBPACK_EXTERNAL_MODULE__946__, __WEBPACK_EXTERNAL_MODULE__487__) {
return 