import { BQ_PROJECT_NAME } from 'config';
import { scaleWeights, scaleAllWeights } from 'utils/helpers';
import { BOUNDARY_DATASETS } from './config/boundaryConfig';

const CRITICALITY_SCALED_BQ_TABLE = `\`${BQ_PROJECT_NAME}.indexing_criticality.h3_r8_scaled\``;
const H3_SCALED_BQ_TABLE = `\`${BQ_PROJECT_NAME}.indexing_us.h3_scaled\``;
const H3_EROSION_BQ_TABLE = `\`${BQ_PROJECT_NAME}.app.erosion_h3\``;

export function getBqTable(sourceName) {
  // get the BQ tablename of this source
  let bqTable;
  BOUNDARY_DATASETS.forEach((config) => {
    if (config.sourceId === sourceName) {
      bqTable = `\`${BQ_PROJECT_NAME}.${config.table}\``;
    }
  });
  return bqTable;
}

export function queryAverageCriticalityIndex(
  weights,
  selectedUuid,
  selectedBoundarySource,
  intersectingH3
) {
  const bqTable = getBqTable(selectedBoundarySource);
  const scaledWeights = scaleWeights(weights);

  const selectList = [];
  for (const [k, v] of Object.entries(scaledWeights)) {
    const select = `(sum(${k} * ${v}) / ${intersectingH3.length})`;
    selectList.push(select);
  }

  const query = `
        select ${selectList.join(' + ')} as avg_score
        from ${CRITICALITY_SCALED_BQ_TABLE}
        where h3 in (
            with d as (
                select intersectingH3 from ${bqTable} where uuid = '${selectedUuid}'
            )
            select x from d, unnest(intersectingH3) as x
        )
    `;

  return query;
}

export function queryAverageH3Index(
  topicWeights,
  datasetWeights,
  selectedUuid,
  selectedBoundarySource,
  intersectingH3
) {
  const bqTable = getBqTable(selectedBoundarySource);

  const scaledWeights = scaleAllWeights(topicWeights, datasetWeights);

  const selectList = [];
  for (const [k, v] of Object.entries(scaledWeights)) {
    const select = `(sum(${k} * ${v}) / ${intersectingH3.length})`;
    selectList.push(select);
  }

  const query = `
          select ${selectList.join(' + ')} as avg_score
          from ${H3_SCALED_BQ_TABLE}
          where h3 in (
            with d as (
                select intersectingH3 from ${bqTable} where uuid = '${selectedUuid}'
            )
            select x from d, unnest(intersectingH3) as x
        )
      `;

  return query;
}


export function queryAverageErosionIndex(
  selectedUuid,
  selectedBoundarySource
) {
  const bqTable = getBqTable(selectedBoundarySource);
  const query = `
  select AVG(rusle) as avg_score
  from ${H3_EROSION_BQ_TABLE}
  where h3 in (
    with d as (
        select intersectingH3 from ${bqTable} where uuid = '${selectedUuid}'
    )
    select x from d, unnest(intersectingH3) as x
)
`;

return query;
}