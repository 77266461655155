import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import TimelineIcon from '@material-ui/icons/Timeline';
import { ReactComponent as CartoLogo } from 'assets/img/carto-logo.svg';
import { ReactComponent as MarkerIcon } from 'assets/img/marker.svg';
import { HDOTLogo } from 'components/common/HDOT/HDOT';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { numberFormatter } from '../../../../../utils/formatter';
import InputField from '../../../routing/source-destination-selector-widget/InputField';
import { SourceDestinationInputs } from '../../../routing/SourceDestinationSelectortWidget';
import { AVGErosion } from '../../components/avg-erosion/AVGErosion';
import { AVGIndex } from '../../components/avg-index/AVGIndex';
import BoundaryProps from '../../components/boundary-props/BoundaryProps';
import DynamicProps from '../../components/dynamic-props/DynamicProps';
import { MapScreenShoot } from './MapScreenShoot';
import Page from './Page';
import AppName from './PageHeader';

const PRINT_HEADER_HEIGHT = '28px';

const useStyles = makeStyles((theme) => ({
  root: {
    '@media print': {
      width: '100%',
    },
  },
  statList: {
    marginBottom: theme.spacing(8),
  },
  page1Wrapper: {
    height: '100%',
  },
  bodyWrapper: {
    minHeight: '350px',
    overflow: 'hidden',
    marginTop: theme.spacing(1),
    width: '100%',
    margin: 0,
    flex: '0 1 auto',
    '& > .MuiGrid-item': {
      paddingBottom: 0,
    },
  },
  hdotLogo: {
    position: 'absolute',
    top: theme.spacing(5),
    right: theme.spacing(0.5),
    zIndex: 1,
    width: '10rem',

    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    '@media print': {
      position: 'absolute',
      display: 'block',
      top: theme.spacing(3),
      right: theme.spacing(0.5),
      zIndex: 1,
      width: '10rem',
    },
  },
}));

function AnalysisIndexContent() {
  const classes = useStyles();
  return (
    <Grid container className={classes.bodyWrapper}>
      <Grid container className={classes.statList}>
        <PrintContent />
      </Grid>
      <Grid container>
        <AVGIndex />
      </Grid>
    </Grid>
  );
}

const useStylesPrintContent = makeStyles((theme) => ({
  widgetsWrapper: {
    width: `50%`,
    '& > div:not(:first-child)': {
      padding: `${theme.spacing(1)}px 0`,
    },
    padding: theme.spacing(1),
  },
}));

const StatGroupWrapper = ({ children }) => {
  const classes = useStylesPrintContent();
  return (
    <Grid item className={classes.widgetsWrapper}>
      {children}
    </Grid>
  );
};

function PrintContent() {
  const selectedBoundarySource = useSelector((state) => state.app.selectedBoundarySource);
  const boundaryProps = useSelector((state) => state.app.boundaryProps);
  return (
    <>
      {selectedBoundarySource === 'userDrawnShapesSource' ? (
        <DynamicProps StatGroupWrapper={StatGroupWrapper} listDivider={false} />
      ) : (
        <BoundaryProps
          props={boundaryProps}
          StatGroupWrapper={StatGroupWrapper}
          withDivider={false}
        />
      )}
    </>
  );
}

function ErosionContent() {
  const classes = useStyles();

  return (
    <Grid container className={classes.bodyWrapper}>
      <AVGErosion />
    </Grid>
  );
}
function RoutingContent() {
  const classes = useStyles();
  const { route, selectedRoads } = useSelector((state) => state.routing);
  return (
    <Grid className={classes.bodyWrapper}>
      <Box px={1}>
        <Grid container justifyContent={'space-between'}>
          <SourceDestinationInputs deletable={false} />
          <InputField
            label='Data'
            value={`Time: ${
              route.trip.summary.time
                ? new Date(route.trip.summary.time * 1000).toISOString().substring(11, 19)
                : 'No time'
            }\nDistance: ${
              route.trip.summary.length
                ? numberFormatter(route.trip.summary.length) + ' miles'
                : 'No distance'
            }`}
            formatter={(value) => value}
            deletable={false}
            startIcon={<TimelineIcon />}
          />
        </Grid>
      </Box>
      {selectedRoads?.length > 0 && (
        <>
          <Box my={2}>
            <Typography align='left' variant='subtitle1'>
              Exclude Points
            </Typography>
          </Box>

          <Grid container>
            {selectedRoads.map((road, index) => (
              <Grid item xs={4}>
                <Box px={1}>
                  <InputField
                    key={index}
                    label={`Point ${index + 1}`}
                    value={road}
                    readOnly={true}
                    focused={false}
                    startIcon={<MarkerIcon />}
                    deletable={false}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Grid>
  );
}

const CONTENT = {
  'analysis-index': <AnalysisIndexContent />,
  'erosion-index': <ErosionContent />,
  routing: <RoutingContent />,
};

export default function ReportContent({ name }) {
  const classes = useStyles();

  const { state } = useLocation();
  const { type } = state;

  return (
    <Grid className={classes.root}>
      <Page disableHeight>
        <Grid container direction='column' className={classes.page1Wrapper}>
          <PrintHeader name={name} />
          <div className={classes.hdotLogo}>
            <HDOTLogo />
          </div>
          <MapScreenShoot />
          {CONTENT[type]}
        </Grid>
      </Page>
      <PrintFooter />
    </Grid>
  );
}

const useStylesPrintHeader = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.primary.main,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    flex: `0 1 ${PRINT_HEADER_HEIGHT}`,
    '@media print': {
      display: 'flex',
    },
  },
  logo: {
    height: theme.spacing(2),
    width: theme.spacing(5),
    marginRight: theme.spacing(1),
  },
  text: {
    color: theme.palette.common.white,
    fontSize: theme.typography.overline.fontSize,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    color: theme.palette.primary.contrastText,
    '@media print': {
      color: theme.palette.primary.contrastText,
      justifyContent: 'flex-end',
    },
  },
}));

function PrintHeader({ name }) {
  const classes = useStylesPrintHeader();

  return (
    <Grid
      container
      direction='row'
      alignItems='center'
      justifyContent='space-between'
      className={classes.root}
    >
      <Grid item>
        <AppName
          className={classes.text}
          logo={<CartoLogo className={classes.logo} />}
          typographyProps={{ variant: 'button' }}
        />
      </Grid>
      <Grid item className={classes.title} justifyContent='flex-end'>
        {name}
      </Grid>
    </Grid>
  );
}

const useFooterStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 0),
    backgroundColor: theme.palette.common.white,
    '@media print': {
      position: 'fixed',
      bottom: 0,
      width: '100%',
      backgroundColor: theme.palette.common.white,
    },
  },
}));

function PrintFooter() {
  const classes = useFooterStyles();
  return (
    <Box
      className={classes.root}
      alignItems='center'
      justifyContent='space-between'
      px={1}
      sx={{ fontWeight: 'light' }}
    >
      <Typography variant='caption' color='textSecondary'>
        © CARTO for Hawaii DOT
      </Typography>
    </Box>
  );
}
