import { Box, makeStyles, Typography, List } from '@material-ui/core';
import { useSelector } from 'react-redux';
import Stat from '../stat/Stat';

const useStyles = makeStyles(() => ({
  singleStat: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  label: {
    marginLeft: '0.5rem',
  },
  value: {
    fontSize: '120%',
    fontWeight: 'bold',
    textAlign: 'right',
  },
  topic: {
    fontWeight: 'bold',
  },
  shapeIsShared: {
    marginBottom: '1rem',
  },
  shapeIsNotShared: {
    marginBottom: '1rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  avgs: {
    marginBottom: '1rem',
  },
  listItem: {
    padding: '0',
    height: 'auto',
  },
}));

export default function StatGroup({ topic, data }) {
  const classes = useStyles();
  return (
    <div>
      <Box py={2}>
        <Typography variant='subtitle1' className={classes.topic}>
          {topic}
        </Typography>
      </Box>
      {data?.length && (
        <List disablePadding>
          {data.map((config) => {
            return (
              <Stat
                key={config.title}
                label={config.nicename}
                value={config.value}
                format={config.format}
              />
            );
          })}
        </List>
      )}
    </div>
  );
}
