import { MAP_TYPES } from '@deck.gl/carto';
import { BQ_PROJECT_NAME, BQ_CONNECTION_NAME } from 'config';

const EROSION_SOURCE_ID = 'erosionSource';

const source = {
  id: EROSION_SOURCE_ID,
  type: MAP_TYPES.TILESET,
  connection: BQ_CONNECTION_NAME,
  data: `${BQ_PROJECT_NAME}.app.erosion_h3_tileset`,
};

export default source;
