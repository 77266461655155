import { executeSQL } from '@carto/react-api';
import { addLayer } from '@carto/react-redux';
import { Box, List, makeStyles, Typography } from '@material-ui/core';
import { BQ_CONNECTION_NAME } from 'config';
import erosionSource from 'data/sources/erosionSource';
import { queryAverageErosionIndex } from 'data/sql_get_index_average';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EROSION_LAYER_ID } from '../../../../layers/ErosionLayer';
import Stat from '../stat/Stat';

const useStyles = makeStyles(() => ({
  wrapper: { width: '100%' },
  caption: { fontWeight: 300, lineHeight: 1.2, color: 'rgba(44, 48, 50, 0.6)' },
  topic: {
    fontWeight: 'bold',
  },
}));

export function AVGErosion() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [avgErosion, setAvgErosion] = useState('Computing...');

  const selectedBoundarySource = useSelector((state) => state.app.selectedBoundarySource);
  const selectedUuid = useSelector((state) => state.app.selectedUuid);
  const credentials = useSelector((state) => state.carto.credentials);

  useEffect(() => {
    async function fetchData() {
      const query = queryAverageErosionIndex(selectedUuid, selectedBoundarySource);

      const result = await executeSQL({
        credentials,
        query,
        connection: BQ_CONNECTION_NAME,
        queryParameters: {}
      });

      if (result && result[0].avg_score) {
        const avgValue = result[0].avg_score.toFixed(3);
        setAvgErosion(avgValue);
      }
    }

    dispatch(
      addLayer({
        id: EROSION_LAYER_ID,
        source: erosionSource.id,
      })
    );

    fetchData();

    return () => {
      setAvgErosion('');
    };
  }, [credentials, selectedBoundarySource, selectedUuid, dispatch]);
  return (
    <Box mb={1} className={classes.wrapper}>
      <Box py={2}>
        <Typography variant='subtitle1' className={classes.topic}>
          Erosion
        </Typography>
      </Box>
      <List disablePadding>
        <Stat label='Average Erosion (t/m³)' value={avgErosion} format='float' />
      </List>
    </Box>
  );
}
