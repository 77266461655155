import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.common.white,
    width: '210mm',
    height: ({ disableHeight }) => (disableHeight ? 'auto' : '296.8mm'),
    display: 'block',
    position: 'relative',
    boxSizing: 'border-box',
    margin: 0,
    // CUSTOM STYLES
    padding: theme.spacing(3),
    marginTop: theme.spacing(2),
    // END CUSTOM STYLES
    '@media print': {
      width: '100%',
      height: ({ disableHeight }) => (disableHeight ? 'auto' : 'calc(296.8mm - 48px)'),
      padding: 0,
      marginTop: 0,
    },
  },
}));

export default function Page({ className, disableHeight = false, children }) {
  const classes = useStyles({ disableHeight });

  return <Box className={`${classes.root} ${className}`}>{children}</Box>;
}
