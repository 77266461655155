import { createSlice } from '@reduxjs/toolkit';

export const ENDPOINT_SOURCE = 'source';
export const ENDPOINT_DESTINATION = 'destination';
export const ENDPOINT_EXCLUDE = 'exclude';

const slice = createSlice({
  name: 'routing',
  initialState: {
    selectedRoads: [],
    endpointSelected: null,
    routeSource: '',
    routeDestination: '',
    route: null,
  },
  reducers: {
    addSelectedRoad: (state, action) => {
      state.selectedRoads.push(action.payload);
    },
    removeSelectedRoad: (state, action) => {
      const index = action.payload;
      state.selectedRoads.splice(index, 1);
    },
    setEndpointToSource: (state, action) => {
      state.endpointSelected = ENDPOINT_SOURCE;
    },
    setEndpointToDestination: (state, action) => {
      state.endpointSelected = ENDPOINT_DESTINATION;
    },
    setEndpointToExclude: (state, action) => {
      state.endpointSelected = ENDPOINT_EXCLUDE;
    },
    setSourceDestination: (state, action) => {
      if (state.endpointSelected === ENDPOINT_SOURCE) {
        state.routeSource = action.payload;
        state.endpointSelected = null;
      } else if (state.endpointSelected === ENDPOINT_DESTINATION) {
        state.routeDestination = action.payload;
        state.endpointSelected = null;
      } else if (state.endpointSelected === ENDPOINT_EXCLUDE) {
        state.selectedRoads.push(action.payload);
        state.endpointSelected = null;
      }
    },
    clearEndpoint: (state, action) => {
      state.endpointSelected = null;
    },
    clearSource: (state, action) => {
      state.routeSource = '';
    },
    clearDestination: (state, action) => {
      state.routeDestination = '';
    },
    setRoute: (state, action) => {
      state.route = action.payload;
    },
  },
});

export default slice.reducer;

export const {
  setRoute,
  addSelectedRoad,
  removeSelectedRoad,
  setEndpointToSource,
  setEndpointToDestination,
  setSourceDestination,
  setEndpointToExclude,
  clearSource,
  clearDestination,
  endpointSelected,
  clearEndpoint,
} = slice.actions;
