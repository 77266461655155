import { useCartoLayerProps } from '@carto/react-api';
import { selectSourceById, updateLayer } from '@carto/react-redux';
import { LEGEND_TYPES } from '@carto/react-ui';
import { CartoLayer } from '@deck.gl/carto';
import { scaleQuantize } from 'd3-scale';
import { useDispatch, useSelector } from 'react-redux';

import { useMemo, useState } from 'react';
import { numberFormatter } from '../../utils/formatter';

export const EROSION_LAYER_ID = 'erosionLayer';

const COLORS = [
  [242, 177, 48],
  [241, 168, 45],
  [239, 151, 40],
  [238, 142, 38],
  [236, 125, 33],
  [234, 107, 29],
  [232, 90, 24],
  [230, 73, 19],
  [228, 55, 15],
  [226, 38, 10],
  [223, 12, 3],
];

export default function ErosionLayer() {
  const dispatch = useDispatch();
  const { erosionLayer } = useSelector((state) => state.carto.layers);
  const source = useSelector((state) => selectSourceById(state, erosionLayer?.source));
  const cartoLayerProps = useCartoLayerProps({ source, layerConfig: erosionLayer });
  const [attributes, setAttributes] = useState(null);

  const appState = useSelector((state) => state.app);
  const colorScale = useMemo(() => {
    const rusleAttribute = attributes?.find((attr) => attr.attribute === 'rusle');
    return scaleQuantize()
      .range(COLORS)
      .domain(rusleAttribute ? [rusleAttribute.min, rusleAttribute.max] : [0, 1000]);
  }, [attributes]);

  const dynamicLineColor = () => {
    const invisibleStyle = [0, 0, 0, 0];
    const visibleStyle = [0, 0, 0, 50];
    if (
      (appState.h3SliderType === 'dataset' && appState.topicBeingAdjusted !== '') ||
      appState.h3SliderType === 'topic'
    ) {
      return invisibleStyle;
    } else {
      return visibleStyle;
    }
  };

  if (erosionLayer && source) {
    return new CartoLayer({
      ...cartoLayerProps,
      id: EROSION_LAYER_ID,
      getFillColor: (d) => colorScale(d.properties.rusle),
      updateTriggers: {
        ...cartoLayerProps.updateTriggers,
        getFillColor: appState,
        getLineColor: appState,
      },
      pointRadiusMinPixels: 2,
      getLineColor: (d) => dynamicLineColor(),
      lineWidthMinPixels: 0.5,
      pickable: true,

      onHover: (info) => {
        if (info?.object) {
          info.object = {
            html: `<p>Erosion: ${numberFormatter(info.object.properties.rusle)} t/m³</p>`,
            style: {},
          };
        }
      },
      onDataLoad: (data) => {
        setAttributes(data.tilestats.layers[0].attributes);
        const rusleAttribute = data.tilestats.layers[0].attributes?.find(
          (attr) => attr.attribute === 'rusle'
        );
        dispatch(
          updateLayer({
            id: EROSION_LAYER_ID,
            layerAttributes: {
              title: 'Erosion',
              visible: true,
              showOpacityControl: true,
              opacity: 1,
              legend: {
                note: 'Larger values indicate higher erosion',
                type: LEGEND_TYPES.CONTINUOUS_RAMP,
                labels: [
                  { label: 'Lower', value: rusleAttribute.min },
                  { label: 'Higher', value: rusleAttribute.max },
                ],
                colors: COLORS,
              },
            },
          })
        );

        cartoLayerProps.onDataLoad && cartoLayerProps.onDataLoad(data);
      },
    });
  }
}
