import React from 'react';
import { BOUNDARY_PROPS } from 'data/config/boundaryPropsConfig';
import StatGroup from '../stat-group/StatGroup';
import { Divider } from '@material-ui/core';

export default function BoundaryProps({
  props,
  withDivider = true,
  StatGroupWrapper = React.Fragment,
}) {
  return (
    <>
      {props &&
        BOUNDARY_PROPS.map((config) => {
          return (
            <>
              <StatGroupWrapper>
                <StatGroup
                  topic={config.topic}
                  data={config.data.map((data) => ({
                    ...data,
                    value: props[data.title],
                  }))}
                  key={config.topic}
                />
              </StatGroupWrapper>
              {withDivider && <Divider />}
            </>
          );
        })}
    </>
  );
}
