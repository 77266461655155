import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  hdotLogo: {
    position: 'absolute',
    top: 'calc(50% - 7.5rem)',
    right: 'calc(50% - 7.5rem)',
    zIndex: 1,
    width: '15rem',
    height: '15rem',

    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  logoBox: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem',
    marginBottom: '2rem',
  },
  logo: {
    maxWidth: '50%',
  },
  loadingBox: {
    display: 'flex',
    justifyContent: 'center',
    // marginBottom: '2rem',
  },
  textBox: {
    textAlign: 'center',
  },
}));

export function ShowSpinner({ text }) {
  const classes = useStyles();

  return (
    <div>
      <div className={classes.loadingBox}>
        <CircularProgress size={'15%'} />
      </div>
      {text && (
        <Box mt={2}>
          <Typography className={classes.textBox} variant='subtitle2' color='primary'>
            {text}
          </Typography>
        </Box>
      )}
    </div>
  );
}

export function HDOTLogo() {
  const classes = useStyles();
  const loadingText = useSelector((state) => state.app.loading);
  if (!loadingText) return null;
  return (
    loadingText && (
      <div className={classes.hdotLogo}>
        <ShowSpinner text={loadingText} />
      </div>
    )
  );
}
