import { VOYAGER } from '@carto/react-basemaps';
import { API_VERSIONS } from '@deck.gl/carto';
import { API_BASE_URL, CLIENT_ID, ORGANIZATION_ID } from 'config';

export const initialState = {
  viewState: {
    latitude: 20.573076587324733,
    longitude: -157.703484467137,
    zoom: 7.7,
    pitch: 0,
    bearing: 0,
    dragRotate: false,
  },
  basemap: VOYAGER,
  credentials: {
    apiVersion: API_VERSIONS.V3,
    apiBaseUrl: API_BASE_URL,
  },
  googleApiKey: 'AIzaSyCvSofcXzvTKTP40zZStjDi6ZL0g4MN01E', // only required when using a Google Basemap,
  googleMapId: '', // only required when using a Google Custom Basemap
  oauth: {
    domain: 'auth.carto.com',
    clientId: CLIENT_ID, // type here your application clientId
    organizationId: ORGANIZATION_ID, // organizationId is required for SSO
    scopes: [
      'read:current_user',
      'update:current_user',
      'read:connections',
      'write:connections',
      'read:maps',
      'write:maps',
      'read:account',
      'admin:account',
    ],
    audience: 'carto-cloud-native-api',
    authorizeEndPoint: 'https://carto.com/oauth2/authorize', // only valid if keeping https://localhost:3000/oauthCallback
  },
};
