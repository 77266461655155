import { MAP_TYPES } from '@deck.gl/carto';
import { BQ_PROJECT_NAME, BQ_CONNECTION_NAME } from 'config';

const default_props = {
  getFillColor: [241, 109, 122, 0],
  pointRadiusMinPixels: 2,
  getLineColor: [255, 0, 0, 150],
  lineWidthMinPixels: 3,
  pickable: true,
  autoHighlight: true,
};

function makeID(prefix, text) {
  return `${prefix}_${text.replace('.', '_')}`;
}

let BOUNDARY_DATASETS = [
  {
    table: 'boundaries.county15',
    nicename: 'Counties',
    popupField: 'namelsad',
    cartoLayerProps: default_props,
  },
  {
    layerId: 'userDrawnShapesLayer',
    sourceId: 'userDrawnShapesSource',
    table: 'internal_app_us.user_drawn_geometries',
    nicename: 'User-drawn shapes',
    popupField: 'name',
    cartoLayerProps: default_props,
    source: {
      id: 'userDrawnShapesSource',
      type: MAP_TYPES.QUERY,
      connection: BQ_CONNECTION_NAME,
      data: `select * from \`${BQ_PROJECT_NAME}.internal_app_us.user_drawn_geometries\``,
      // data: `${BQ_PROJECT_NAME}.internal_app_us.user_drawn_geometries`,
      layerId: 'userDrawnShapesLayer',
      cartoLayerProps: default_props,
    },
  },
  {
    table: 'boundaries.cdplc15',
    nicename: 'Census Designated Places',
    popupField: 'namelsad',
    cartoLayerProps: default_props,
  },
  {
    table: 'boundaries.county_council_districts_simplified',
    nicename: 'County Council Districts',
    popupField: 'place_name',
    cartoLayerProps: default_props,
  },
  {
    table: 'boundaries.hawaiianhomelands15',
    nicename: 'Hawaiian Homelands',
    popupField: 'namelsad',
    cartoLayerProps: default_props,
  },
  {
    table: 'boundaries.political_legislative_districts_house_2022_simplified',
    nicename: 'State House (2022)',
    popupField: 'house_name',
    cartoLayerProps: default_props,
  },
  {
    table: 'boundaries.political_legislative_districts_senate_2022',
    nicename: 'State Senate (2022)',
    popupField: 'sen_name',
    cartoLayerProps: default_props,
  },
  {
    table: 'boundaries.political_legislative_districts_usa_congress_2022',
    nicename: 'USA Congress (2022)',
    popupField: 'us_hs_name',
    cartoLayerProps: default_props,
  },
];

BOUNDARY_DATASETS.forEach((config) => {
  if (config.layerId !== 'userDrawnShapesLayer') {
    config['sourceId'] = makeID('source', config.table);
    config['layerId'] = makeID('layer', config.table);
    config['source'] = {
      id: config.sourceId,
      type: MAP_TYPES.TABLE,
      connection: BQ_CONNECTION_NAME,
      data: `${BQ_PROJECT_NAME}.${config.table}`,
      layerId: config.layerId,
      cartoLayerProps: config.cartoLayerProps,
    };
  }
});

const BOUNDARY_LOOKUP = {};
BOUNDARY_DATASETS.map((config) => {
  return (BOUNDARY_LOOKUP[config.table] = config);
});

export { BOUNDARY_LOOKUP, BOUNDARY_DATASETS };
