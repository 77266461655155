import deepmerge from 'deepmerge';
import { createTheme } from '@material-ui/core';
import { cartoThemeOptions } from '@carto/react-ui';

const { palette, spacing, breakpoints, typography } = createTheme(
  deepmerge(cartoThemeOptions, {})
);

const customTheme = {
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#036fe2',
    },
  },
  typography: {
    h1: {
      fontWeight: 700,
    },
    h2: {
      fontWeight: 700,
    },
    h3: {
      fontWeight: 700,
      [breakpoints.down('xs')]: {
        fontSize: '34px',
      },
    },
    h4: {
      fontWeight: 700,
    },
    h5: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 600,
    },
    subtitle1: {
      fontWeight: 600,
    },
    body1: {
      fontSize: 20,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  },
  overrides: {
    MuiListItem: {
      root: {
        height: spacing(7),
      },
      divider: {
        '&:last-of-type': {
          borderBottom: 'none',
        },
      },
    },
    MuiSelect: {
      selectMenu: {
        display: 'flex',
        alignItems: 'center',
        height: spacing(4.5),
      },
      select: {
        paddingTop: 0,
        paddingBottom: 0,
        '&.MuiSelect-select': {
          ...typography.caption,
          color: palette.text.secondary,
          paddingRight: `${spacing(1.5)}px !important`,
        },
        '&.MuiSelect-filled': {
          ...typography.body2,
          color: palette.text.primary,
        },
      },
    },
    MuiTabs: {
      indicator: {
        '&.colorPrimary': {
          height: 3,
          transitionProperty: 'width',
        },
      },
      root: {
        '&$vertical .MuiTabs-indicator': {
          width: 2,
          transitionProperty: 'height',
          height: 3,
        },
      },
    },
    MuiTab: {
      root: {
        '& span, & .Mui-icon': {
          whiteSpace: 'nowrap',
        },
        '&.Mui-selected span': {},
      },
    },
  },
};

const theme = createTheme(deepmerge(cartoThemeOptions, customTheme));

export default theme;
